module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"#b93529","showSpinner":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Downloader.Tube","short_name":"Downloader.Tube","start_url":"/","background_color":"#b93529","theme_color":"#b93529","display":"minimal-ui","icon":"src/favicon_io/android-chrome-192x192.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"b3f067e7e9ac86b77c73463ab4823e7c"},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["en","es","de","fr","it","pt","nb","el","cs","lv","sq","hy","tr","pl","ru","hi","ka","uk","ja","zh","fa","ar"],"defaultLanguage":"en","generateDefaultLanguagePage":false,"siteUrl":"https://downloader.tube","i18nextOptions":{"defaultNS":"common","fallbackLng":"en","lowerCaseLng":true,"interpolation":{"escapeValue":false}}},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://downloader.tube"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
